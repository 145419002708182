import { FaUserCircle } from "react-icons/fa";

export let navLinks = [
  {
    id: 1,
    title: "Accueil",
    href: "/",
  },
  {
    id: 2,
    title: "Prestations",
    href: "/prestations",
  },
  {
    id: 3,
    title: "Réservation",
    href: "/reservation",
  },
  {
    id: 4,
    title: "Nos réalisations",
    href: "/realisations",
  },
  {
    id: 5,
    title: "Mes rendez-vous",
    href: "/rendez-vous",
    icon: <FaUserCircle />,
  },
];
