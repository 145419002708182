export const GET_DATAS_REQUEST = "GET_DATAS_REQUEST";
export const GET_DATAS_SUCCESS = "GET_DATAS_SUCCESS";
export const GET_DATAS_FAILLED = "GET_DATAS_FAILLED";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILLED = "GET_CATEGORIES_FAILLED";

export const GET_PRESTATRICES_REQUEST = "GET_PRESTATRICES_REQUEST";
export const GET_PRESTATRICES_SUCCESS = "GET_PRESTATRICES_SUCCESS";
export const GET_PRESTATRICES_FAILLED = "GET_PRESTATRICES_FAILLED";

export const GET_APPOINTMENTS_REQUEST = "GET_APPOINTMENTS_REQUEST";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_FAILLED = "GET_APPOINTMENTS_FAILLED";

export const GET_REALISATIONS_REQUEST = "GET_REALISATIONS_REQUEST";
export const GET_REALISATIONS_SUCCESS = "GET_REALISATIONS_SUCCESS";
export const GET_REALISATIONS_FAILLED = "GET_REALISATIONS_FAILLED";

export const CHANGE_CALENDAR_INDEX = "CHANGE_CALENDAR_INDEX";
export const CHANGE_ACTIVE_DATE = "CHANGE_ACTIVE_DATE";
export const CHANGE_ACTIVE_PRESTATRICE = "CHANGE_ACTIVE_PRESTATRICE";

export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE";
export const SET_SELECTED_OPTIONS = "SET_SELECTED_OPTIONS";
export const SET_SELECTED_COIFFURE_OPTION = "SET_SELECTED_COIFFURE_OPTION";
export const DEL_SELECTED_OPTIONS = "DEL_SELECTED_OPTIONS";
export const CLEAR_SELECTED_OPTIONS = "CLEAR_SELECTED_OPTIONS";
export const SET_ACTIVE_CALENDAR_ARRAY = "SET_ACTIVE_CALENDAR_ARRAY";

export const GET_CALENDAR_DATA_REQUEST = "GET_CALENDAR_DATA_REQUEST";
export const GET_CALENDAR_DATA_SUCCESS = "GET_CALENDAR_DATA_SUCCESS";
export const GET_CALENDAR_DATA_FAILLED = "GET_CALENDAR_DATA_FAILLED";

