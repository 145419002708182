import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";
import { useSelector } from "react-redux";

function HeaderLink(props) {
  const { link } = props;
  const pathname = useLocation().pathname;
  const { categories } = useSelector((state) => state.categoryReducer);
  const [prestationsList, setPrestationsList] = useState([]);

  useEffect(() => {
    let array = [];
    for (let i = 0; i < categories.length; i++) {
      array.push(categories[i].url);
    }
    setPrestationsList(array);
  }, [categories]);

  return link.href !== "/manucure" ? (
    <Link
      to={link.href}
      className={pathname === link.href ? "header-link-active" : "header-link"}
    >
      <span style={{ marginRight: link.icon ? 10 : 0 }}>{link.title}</span>
      {link.icon && link.icon}
    </Link>
  ) : (
    <Link
      to={link.href}
      className={
        prestationsList.includes(pathname)
          ? "header-link-active"
          : "header-link"
      }
    >
      {link.title}
    </Link>
  );
}

export default HeaderLink;
