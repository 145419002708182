import React from "react";
import "./styles.css";
import { Col, Row } from "antd";
import { HeaderLogo } from "../../atoms";
import { navLinks } from "../../../utils/nav-links/navLinks";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  const { informations } = useSelector((state) => state.categoryReducer);
  return (
    <footer className="footer">
      <div className="main_container">
        <div className="footer-container">
          <Row>
            <Col lg={8} xs={24}>
              <div className="footer-logo">
                <HeaderLogo />
                <div className="footer-social-icon-container">
                  <Link
                    to="https://www.facebook.com/hamik.adamion"
                    target="_blank"
                  >
                    <img
                      src={require("../../../images/icons/facebook.webp")}
                      alt="facebook"
                      className="footer-social-icon facebook-icon"
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/yevanails_creil/"
                    target="_blank"
                  >
                    <img
                      src={require("../../../images/icons/instagram.webp")}
                      alt="instagram"
                      className="footer-social-icon"
                    />
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={8} xs={24}>
              <div className="footer-pages">
                <div>
                  <h2>Pages</h2>
                  <div className="footer-links-container">
                    {navLinks.map((link) => (
                      <Link
                        className="footer-link"
                        key={link.id}
                        to={link.href}
                      >
                        {link.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} xs={24}>
              <div className="footer-contact">
                <div>
                  <h2>Nous contacter</h2>
                  <div className="footer-informations-container">
                    <div className="footer-image-container">
                      <img
                        src={require("../../../images/icons/phone.webp")}
                        alt="phone"
                      />
                    </div>
                    <div className="footer-informations">
                      {informations.phone.match(/.{2}/g).join(" ")}
                    </div>
                  </div>
                  {informations.email && (
                    <div className="footer-informations-container">
                      <div className="footer-image-container">
                        <img
                          src={require("../../../images/icons/mail.webp")}
                          alt="mail"
                        />
                      </div>
                      <div className="footer-informations">
                        {informations.email}
                      </div>
                    </div>
                  )}
                  <div className="footer-informations-container">
                    <div className="footer-image-container">
                      <img
                        src={require("../../../images/icons/location.webp")}
                        alt="location"
                      />
                    </div>
                    <div>
                      <div className="footer-informations">
                        {informations.address}
                      </div>
                      {informations.additionnal && (
                        <div className="footer-informations">
                          {informations.additionnal}
                        </div>
                      )}
                      <div className="footer-informations">
                        {informations.zip}, {informations.city}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-bottom-bar">
        Copyright ©{new Date().getFullYear()} - Yeva Nails développé par la
        société{" "}
        <Link target="_blank" to="http://k-dev.fr/">
          K-DEV
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
