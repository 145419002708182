import React, { useEffect, useState } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { RiCloseLine } from "react-icons/ri";
import { Select } from "antd";
import {
  //changeActiveDate,
  clearSelectedOptions,
  delSelectedOptions,
  setActiveCalendarArray,
  setSelectedCategory,
  setSelectedCoiffureOption,
  setSelectedOptions,
  setSelectedService,
} from "../../../redux/actions/categoryActions";
import { LoadingScreenTransparent } from "../../templates";

function PrestationsCalendar() {
  const dispatch = useDispatch();
  const {
    categories,
    options,
    appointments,
    selectedCategory,
    selectedService,
    selectedOptions,
    calendarLoading,
    selectedCoiffureOptions,
  } = useSelector((state) => state.categoryReducer);

  const [categoriesArray, setCategoriesArray] = useState([]);
  const [servicesArray, setServicesArray] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);

  useEffect(() => {
    const filteredCategories = categories.map((item) => ({
      value: item._id,
      label: item.name,
    }));
    setCategoriesArray(filteredCategories);
    const index = categories.findIndex((item) => item._id === selectedCategory);
    if (index >= 0 && categories[index].services.length > 0) {
      const services = categories[index].services.map((service) => ({
        value: service._id,
        label: `${service.name} - ${service.price}€ (${service.duration}min)`,
      }));
      setServicesArray(services);
    }
    if (selectedCategory) {
      onSelectCategory(selectedCategory);
    }

    if (selectedService) {
      onSelectService(selectedService);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, selectedCategory, appointments]);

  const onSelectCategory = (category) => {
    clearSelectedOptions(dispatch);
    const categoryIndex = categories.findIndex((item) => item._id === category);
    const servicesList = categories[categoryIndex].services;
    const optionsList = categories[categoryIndex].options;
    setSelectedCategory(category, dispatch);
    const filteredServices = servicesList.map((service) => ({
      value: service._id,
      label: `${service.name} - ${service.price}€ (${service.duration}min)`,
    }));

    let filteredOptions = [];

    if (
      categories &&
      selectedCategory &&
      categories[categories.findIndex((item) => item._id === selectedCategory)]
        .short === "Coiffure"
    ) {
      const optionsListTemp = optionsList.filter(
        (item) => item.subCategory === selectedService
      );
      filteredOptions = optionsListTemp.map((option) => ({
        value: option._id,
        label: `${option.name} - ${option.price}€`,
      }));
    } else {
      filteredOptions = optionsList.map((option) => ({
        value: option._id,
        label: `${option.name} - ${option.price}€`,
      }));
    }
    setServicesArray(filteredServices);
    setOptionsArray(filteredOptions);
    if (filteredServices.length > 0) {
      setSelectedService(filteredServices[0].value, dispatch);
    } else {
      setSelectedService(null, dispatch);
    }

    const calendarTemp = [];
    for (let i = 0; i < appointments.length; i++) {
      const servicesListTemp = [];
      for (let j = 0; j < appointments[i].calendar.length; j++) {
        const cal = appointments[i].calendar[j];

        let sum = 0;
        cal.prestatrices?.map((item) =>
          item.appointmentSchedule.map((app) => {
            sum = sum + app.appointments_30.length;
            sum = sum + app.appointments_60.length;
            sum = sum + app.appointments_90.length;
            sum = sum + app.appointments_120.length;
            sum = sum + app.appointments_150.length;
            sum = sum + app.appointments_180.length;
            sum = sum + app.appointments_210.length;
            sum = sum + app.appointments_240.length;
            return sum;
          })
        );
        if (sum === 0) {
          let object = {
            ...cal,
            visible: false,
          };
          servicesListTemp.push(object);
        } else {
          let object = {
            ...cal,
            visible: true,
            prestatrices: cal.prestatrices,
          };
          servicesListTemp.push(object);
        }
      }
      //changeActiveDate()
      calendarTemp.push({
        ...appointments[i],
        calendar: servicesListTemp,
      });
    }
    /*for (let i = 0; i < calendarTemp[0].calendar.length; i++) {
      let item = calendarTemp[0].calendar[i];
      if (item.visible && item.active) {
        return changeActiveDate(item.date, dispatch);
      }
    }*/
    setActiveCalendarArray(calendarTemp, dispatch);
  };

  const onSelectService = (value) => {
    setSelectedService(value, dispatch);
    /*const categoryIndex = categories.findIndex(
      (item) => item._id === selectedCategory
    );
    const optionsList = categories[categoryIndex].options;
    const calendarTemp = [];

    let filteredOptions = [];

    if (
      categories &&
      selectedCategory &&
      categories[categories.findIndex((item) => item._id === selectedCategory)]
        .short === "Coiffure"
    ) {
      const optionsListTemp = optionsList.filter(
        (item) => item.subCategory === value
      );
      filteredOptions = optionsListTemp.map((option) => ({
        value: option._id,
        label: `${option.name} - ${option.price}€`,
      }));
    } else {
      filteredOptions = optionsList.map((option) => ({
        value: option._id,
        label: `${option.name} - ${option.price}€`,
      }));
    }
    setOptionsArray(filteredOptions);
    for (let i = 0; i < appointments.length; i++) {
      const servicesListTemp = [];
      for (let j = 0; j < appointments[i].calendar.length; j++) {
        const cal = appointments[i].calendar[j];

        let sum = 0;
        cal.prestatrices?.map((item) =>
          item.appointmentSchedule.map((app) => {
            sum = sum + app.appointments_30.length;
            sum = sum + app.appointments_60.length;
            sum = sum + app.appointments_90.length;
            sum = sum + app.appointments_120.length;
            sum = sum + app.appointments_150.length;
            sum = sum + app.appointments_180.length;
            sum = sum + app.appointments_210.length;
            sum = sum + app.appointments_240.length;
            return sum;
          })
        );
        if (sum === 0) {
          let object = {
            ...cal,
            visible: false,
          };
          servicesListTemp.push(object);
        } else {
          let object = {
            ...cal,
            visible: true,
            prestatrices: cal.prestatrices,
          };
          servicesListTemp.push(object);
        }
      }
      calendarTemp.push({
        ...appointments[i],
        calendar: servicesListTemp,
      });
    }
    setActiveCalendarArray(calendarTemp, dispatch);*/
  };

  const onSelectCoiffureOption = (option) => {
    setSelectedCoiffureOption(option, dispatch);
  };

  const onSelectOption = (option) => {
    setSelectedOptions(option, dispatch);
  };

  const onDeleteOption = (option) => {
    delSelectedOptions(option, dispatch);
  };

  return (
    <div className="main_container">
      {calendarLoading && (
        <div style={{ position: "absolute", height: "100%", width: "100%" }}>
          <LoadingScreenTransparent />
        </div>
      )}
      <div className="prestatrices-calendar-container">
        <div className="prestatrices-calendar-title">
          <h2>Selectionnez votre prestation</h2>
          <div style={{ margin: "0 20px" }}>
            <div>
              <h4>Categorie</h4>
            </div>
            <Select
              className="prestations_form_input"
              options={categoriesArray}
              onChange={onSelectCategory}
              value={selectedCategory}
              placeholder="Selectionnez la catégory de la prestation"
            />
            <div>
              <h4>Prestation</h4>
            </div>
            <Select
              className="prestations_form_input"
              options={servicesArray}
              onChange={onSelectService}
              value={selectedService}
              disabled={!selectedCategory}
              style={!selectedCategory && { backgroundColor: "lightgray" }}
            />
            {categories[
              categories.findIndex((item) => item._id === selectedCategory)
            ]?.short === "Coiffure" ? (
              <div>
                <div>
                  <h4>Options</h4>
                </div>
                <Select
                  className="prestations_form_input"
                  options={optionsArray}
                  onChange={onSelectCoiffureOption}
                  value={selectedCoiffureOptions}
                  disabled={!selectedService || optionsArray.length === 0}
                  style={
                    !selectedService ||
                    (optionsArray.length === 0 && {
                      backgroundColor: "lightgray",
                    })
                  }
                />
              </div>
            ) : (
              <div>
                <div>
                  <h4>Options</h4>
                </div>
                <div>
                  {selectedOptions.map((id) => {
                    const option =
                      options[options.findIndex((item) => item._id === id)];
                    return (
                      <div key={id} className="reservationOption">
                        <div>
                          {option.name} - {option.price}€
                        </div>
                        <div
                          onClick={() => onDeleteOption(option._id)}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <RiCloseLine color="white" />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Select
                  className="prestations_form_input"
                  options={optionsArray.filter(
                    (item) => !selectedOptions.includes(item.value)
                  )}
                  onChange={onSelectOption}
                  disabled={!selectedService || optionsArray.length === 0}
                  style={
                    !selectedService ||
                    (optionsArray.length === 0 && {
                      backgroundColor: "lightgray",
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrestationsCalendar;
