import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

function HeaderLogo() {
  return (
    <Link to="/">
      <img
        src={require("../../../images/logos/logo-3.webp")}
        alt="Yeva Nails"
        className="header-logo"
      />
    </Link>
  );
}

export default HeaderLogo;
