import React from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "antd";

function PrestationsMenu() {
  const { categories } = useSelector((state) => state.categoryReducer);
  const pathname = useLocation().pathname;
  return (
    <div className="prestations-menu">
      <Row>
        {categories.map((category, i) =>
          pathname === category.url ? (
            <Col lg={24} md={6} xs={24} key={category._id}>
              <Link to={category.url}>
                <div className="prestations-menu-title">{category.short}</div>
                {i < categories.length - 1 && (
                  <div className="prestations-menu-border" />
                )}
              </Link>
            </Col>
          ) : category.services.length > 0 ? (
            <Col lg={24} md={6} xs={24} key={category._id}>
              <Link to={category.url}>
                <div className="prestations-menu-title inactive-link">
                  {category.short}
                </div>
                {i < categories.length - 1 && (
                  <div className="prestations-menu-border inactive-link" />
                )}
              </Link>
            </Col>
          ) : (
            <Col lg={24} md={6} xs={24} key={category._id}>
              <span>
                <div className="prestations-menu-title-span inactive-link">
                  {category.short}
                </div>
                {i < categories.length - 1 && (
                  <div className="prestations-menu-border inactive-link" />
                )}
              </span>
            </Col>
          )
        )}
      </Row>
    </div>
  );
}

export default PrestationsMenu;
