import "./App.css";
import Navigation from "./routes/Navigation";
import store from "../src/redux/store";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";
import "@fontsource/praise";
import "@fontsource/praise/400.css";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#c3ae6e",
        },
      }}
    >
      <Provider store={store}>
        <ToastContainer autoClose={2000} theme="colored" />
        <Navigation />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
