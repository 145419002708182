import axios from "axios";
import {
  CANCEL_RESERVATION_URL,
  GET_CALENDAR_URL,
  GET_CATEGORIES_URL,
  GET_DATAS_URL,
  GET_PRESTATRICES_URL,
  GET_REALISATIONS_URL,
  GET_RESERVATIONS_URL,
  NEW_RESERVATION_URL,
} from "../url/url";
import {
  CHANGE_ACTIVE_DATE,
  CHANGE_ACTIVE_PRESTATRICE,
  CHANGE_CALENDAR_INDEX,
  CLEAR_SELECTED_OPTIONS,
  DEL_SELECTED_OPTIONS,
  GET_CALENDAR_DATA_FAILLED,
  GET_CALENDAR_DATA_REQUEST,
  GET_CALENDAR_DATA_SUCCESS,
  GET_CATEGORIES_FAILLED,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_DATAS_FAILLED,
  GET_DATAS_REQUEST,
  GET_DATAS_SUCCESS,
  GET_PRESTATRICES_FAILLED,
  GET_PRESTATRICES_REQUEST,
  GET_PRESTATRICES_SUCCESS,
  GET_REALISATIONS_FAILLED,
  GET_REALISATIONS_REQUEST,
  GET_REALISATIONS_SUCCESS,
  SET_ACTIVE_CALENDAR_ARRAY,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_COIFFURE_OPTION,
  SET_SELECTED_OPTIONS,
  SET_SELECTED_SERVICE,
} from "../types/categoryTypes";
import { toast } from "react-toastify";

export const getDatas = (dispatch) => {
  dispatch({
    type: GET_DATAS_REQUEST,
  });
  axios
    .get(GET_DATAS_URL)
    .then((res) => {
      dispatch({
        type: GET_DATAS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_DATAS_FAILLED,
      });
    });
};

export const getCategories = (dispatch) => {
  dispatch({
    type: GET_CATEGORIES_REQUEST,
  });
  axios
    .get(GET_CATEGORIES_URL)
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CATEGORIES_FAILLED,
      });
    });
};

export const getPrestatrices = (dispatch) => {
  dispatch({
    type: GET_PRESTATRICES_REQUEST,
  });
  axios
    .get(GET_PRESTATRICES_URL)
    .then((res) => {
      dispatch({
        type: GET_PRESTATRICES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PRESTATRICES_FAILLED,
      });
    });
};

export const getCalendar = (dispatch) => {
  dispatch({
    type: GET_CALENDAR_DATA_REQUEST,
  });
  axios
    .post(GET_CALENDAR_URL, null)
    .then((res) => {
      return dispatch({
        type: GET_CALENDAR_DATA_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: GET_CALENDAR_DATA_FAILLED,
      });
    });
};

export const getRealisations = (dispatch) => {
  dispatch({
    type: GET_REALISATIONS_REQUEST,
  });
  axios
    .get(GET_REALISATIONS_URL)
    .then((res) => {
      return dispatch({
        type: GET_REALISATIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: GET_REALISATIONS_FAILLED,
      });
    });
};

export const changeCalendarIndex = (value, dispatch) => {
  dispatch({
    type: CHANGE_CALENDAR_INDEX,
    payload: value,
  });
};

export const changeActiveDate = (value, dispatch) => {
  dispatch({
    type: CHANGE_ACTIVE_DATE,
    payload: value,
  });
};

export const changeActivePrestatrice = (
  presta,
  time,
  activeCategory,
  activeService,
  dispatch
) => {
  dispatch({
    type: CHANGE_ACTIVE_PRESTATRICE,
    payload: { presta, time, activeCategory, activeService },
  });
};

export const setSelectedCategory = (value, dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORY,
    payload: value,
  });
};
export const setSelectedService = (value, dispatch) => {
  dispatch({
    type: SET_SELECTED_SERVICE,
    payload: value,
  });
};
export const setSelectedOptions = (value, dispatch) => {
  dispatch({
    type: SET_SELECTED_OPTIONS,
    payload: value,
  });
};
export const setSelectedCoiffureOption = (value, dispatch) => {
  dispatch({
    type: SET_SELECTED_COIFFURE_OPTION,
    payload: value,
  });
};
export const delSelectedOptions = (value, dispatch) => {
  dispatch({
    type: DEL_SELECTED_OPTIONS,
    payload: value,
  });
};

export const clearSelectedOptions = (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_OPTIONS });
};

export const setActiveCalendarArray = (value, dispatch) => {
  dispatch({
    type: SET_ACTIVE_CALENDAR_ARRAY,
    payload: value,
  });
};

export const newReservation = (body, goToCalendar, dispatch) => {
  axios
    .post(NEW_RESERVATION_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      changeCalendarIndex(0, dispatch);
      goToCalendar();
      getCalendar(dispatch);
      setSelectedCategory(null, dispatch);
      setSelectedService(null, dispatch);
      clearSelectedOptions(dispatch);
    })
    .catch((err) => {
      getCalendar(dispatch);
      toast(err.response.data.message, { type: "error" });
    });
};

export const getReservations = (body) => {
  return axios.post(GET_RESERVATIONS_URL, body);
};

export const cancelReservation = (id) => {
  return axios.delete(`${CANCEL_RESERVATION_URL}/${id}`);
};