import { Button } from "antd";
import React from "react";
import "./styles.css";

function MyButtonPhone({ title, href }) {
  return (
    <Button className="my-button-phone" href={href}>
      {title}
    </Button>
  );
}

export default MyButtonPhone;
