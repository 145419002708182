import React from "react";
import "./styles.css";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { filteredTime } from "../../../utils/dateList";

function Openning() {
  const { informations, schedule } = useSelector(
    (state) => state.categoryReducer
  );
  return (
    <div className="main_container">
      <div
        className="openning"
        style={{
          backgroundImage: informations?.openingImage
            ? `url(${informations.openingImage})`
            : "",
        }}
      >
        <div className="openning-hover">
          <Row className="time-row-container">
            <Col lg={12} md={16} xs={24}>
              <div className="time-container">
                <h2>Horaires d'ouverture</h2>
                {schedule.map((day, i) => (
                  <div key={i}>
                    <h3>
                      {day.from}
                      {day.to && ` au ${day.to}`}
                    </h3>
                    {day.morning && !day.afternoon && (
                      <h3>
                        {filteredTime(day.morning.from)}-
                        {filteredTime(day.morning.to)}
                      </h3>
                    )}
                    {!day.morning && day.afternoon && (
                      <h3>
                        {filteredTime(day.afternoon.from)}-
                        {filteredTime(day.afternoon.to)}
                      </h3>
                    )}
                    {day.morning && day.afternoon && (
                      <p>
                        {filteredTime(day.morning.from)}-
                        {filteredTime(day.morning.to)} et{" "}
                        {filteredTime(day.afternoon.from)}-
                        {filteredTime(day.afternoon.to)}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Openning;
