import {
  CHANGE_ACTIVE_DATE,
  CHANGE_ACTIVE_PRESTATRICE,
  CHANGE_CALENDAR_INDEX,
  CLEAR_SELECTED_OPTIONS,
  DEL_SELECTED_OPTIONS,
  GET_CALENDAR_DATA_FAILLED,
  GET_CALENDAR_DATA_REQUEST,
  GET_CALENDAR_DATA_SUCCESS,
  GET_CATEGORIES_FAILLED,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_DATAS_FAILLED,
  GET_DATAS_REQUEST,
  GET_DATAS_SUCCESS,
  GET_PRESTATRICES_FAILLED,
  GET_PRESTATRICES_REQUEST,
  GET_PRESTATRICES_SUCCESS,
  GET_REALISATIONS_FAILLED,
  GET_REALISATIONS_REQUEST,
  GET_REALISATIONS_SUCCESS,
  SET_ACTIVE_CALENDAR_ARRAY,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_COIFFURE_OPTION,
  SET_SELECTED_OPTIONS,
  SET_SELECTED_SERVICE,
} from "../types/categoryTypes";

const initialeState = {
  informations: null,
  dataLoading: true,
  categories: [],
  categoryLoading: true,
  options: [],
  optionLoading: true,
  prestatrices: [],
  prestatriceLoading: true,
  appointments: [],
  calendarLoadingLoading: true,
  realisations: [],
  realisationsLoading: true,
  appointmentsIndex: 0,
  activeDate: "",
  activePrestatrice: "",
  activeTime: "",
  activeCategory: "",
  activeService: "",
  selectedCategory: null,
  selectedService: null,
  selectedCoiffureOptions: "",
  selectedOptions: [],
  activeCalendarArray: [],
  schedule: [],
  services: [],
};

const categoryReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DATAS_REQUEST:
      return {
        ...state,
        dataLoading: true,
      };
    case GET_DATAS_SUCCESS:
      return {
        ...state,
        informations: payload.informations,
        categories: payload.categories,
        options: payload.options,
        prestatrices: payload.prestatrices,
        realisations: payload.realisations,
        services: payload.services,
        schedule: payload.schedule,
        realisationsLoading: false,
        prestatriceLoading: false,
        categoryLoading: false,
        dataLoading: false,
      };
    case GET_DATAS_FAILLED:
      return {
        ...state,
        realisationsLoading: false,
        prestatriceLoading: false,
        categoryLoading: false,
        dataLoading: false,
      };
    case GET_CALENDAR_DATA_REQUEST:
      return {
        ...state,
        calendarLoading: true,
      };
    case GET_CALENDAR_DATA_SUCCESS:
      return {
        ...state,
        appointments: payload.appointments,
        activeDate: payload.activeDate,
        calendarLoading: false,
      };
    case GET_CALENDAR_DATA_FAILLED:
      return {
        ...state,
        calendarLoading: false,
      };
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        categoryLoading: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
        categoryLoading: false,
      };
    case GET_CATEGORIES_FAILLED:
      return {
        ...state,
        categoryLoading: false,
      };
    case GET_PRESTATRICES_REQUEST:
      return {
        ...state,
        prestatriceLoading: true,
      };
    case GET_PRESTATRICES_SUCCESS:
      return {
        ...state,
        prestatrices: payload,
        prestatriceLoading: false,
      };
    case GET_PRESTATRICES_FAILLED:
      return {
        ...state,
        prestatriceLoading: false,
      };
    case GET_REALISATIONS_REQUEST:
      return {
        ...state,
        realisationsLoading: true,
      };
    case GET_REALISATIONS_SUCCESS:
      return {
        ...state,
        realisations: payload,
        realisationsLoading: false,
      };
    case GET_REALISATIONS_FAILLED:
      return {
        ...state,
        realisationsLoading: false,
      };
    case CHANGE_CALENDAR_INDEX:
      return {
        ...state,
        appointmentsIndex: payload,
      };
    case CHANGE_ACTIVE_DATE:
      return {
        ...state,
        activeDate: payload,
      };
    case CHANGE_ACTIVE_PRESTATRICE:
      return {
        ...state,
        activePrestatrice: payload.presta,
        activeTime: payload.time,
        activeCategory: payload.activeCategory,
        activeService: payload.activeService,
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
        selectedOptions: [],
        selectedCoiffureOptions: null,
      };
    case SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: payload,
        selectedOptions: [],
        selectedCoiffureOptions: null,
      };
    case SET_SELECTED_COIFFURE_OPTION:
      return {
        ...state,
        selectedCoiffureOptions: payload,
      };
    case SET_SELECTED_OPTIONS:
      return {
        ...state,
        selectedOptions: [...state.selectedOptions, payload],
      };
    case DEL_SELECTED_OPTIONS:
      return {
        ...state,
        selectedOptions: state.selectedOptions.filter(
          (item) => item !== payload
        ),
      };
    case CLEAR_SELECTED_OPTIONS:
      return {
        ...state,
        selectedOptions: [],
      };
    case SET_ACTIVE_CALENDAR_ARRAY:
      return {
        ...state,
        activeCalendarArray: payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
