import React, { useEffect, useState } from "react";
import { Openning, PrestationsBlock } from "../../organims";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingScreen from "../loading-screen/LoadingScreen";

function PrestationsTemplate() {
  const pathname = useLocation().pathname;
  const { categories } = useSelector((state) => state.categoryReducer);
  const [activeCategory, setActiveCategory] = useState(null);
  useEffect(() => {
    const index = categories.findIndex((item) => item.url === pathname);
    setActiveCategory(categories[index]);
  }, [activeCategory, categories, pathname]);
  return activeCategory ? (
    <div>
      <div className="main_container">
        <div className="prestation_title_container">
          <div className="prestation_title">
            <h2>
              <span>{activeCategory.name}</span>
            </h2>
          </div>
        </div>
      </div>
      {/*<ServiceTopPage activeCategory={activeCategory} />*/}
      <PrestationsBlock activeCategory={activeCategory} />
      <Openning />
    </div>
  ) : (
    <LoadingScreen />
  );
}

export default PrestationsTemplate;
