import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Footer, Header, LoadingScreen } from "../components/templates";
import {
  Appointment,
  Home,
  Prestations,
  Realisations,
  RendezVous,
  Reservation,
} from "../pages";
import "./styles.css";
import { getCalendar, getDatas } from "../redux/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";

function Navigation(props) {
  const dispatch = useDispatch();
  const { categories, categoryLoading } = useSelector(
    (state) => state.categoryReducer
  );
  useEffect(() => {
    getDatas(dispatch);
    getCalendar(dispatch);
  }, [dispatch]);
  return categoryLoading && !categories?.length > 0 ? (
    <LoadingScreen />
  ) : (
    <Router>
      <Header />
      <main className="main">
        <img
          className="ellipse-2"
          src={require("../images/backgrounds/Ellipse-3.webp")}
          alt="Ellispse 1"
        />
        <img
          className="ellipse-3"
          src={require("../images/backgrounds/Ellipse-2.webp")}
          alt="Ellispse 2"
        />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Navigate replace to="/" />} />
          {categories.map(
            (category) =>
              category.services.length > 0 && (
                <Route
                  key={category._id}
                  exact
                  path={category.url}
                  element={<Prestations />}
                />
              )
          )}
          <Route
            exact
            path="/prestations"
            element={<Navigate replace to={categories[0].url} />}
          />
          prestations
          <Route exact path="/reservation" element={<Reservation />} />
          <Route exact path="/appointment" element={<Appointment />} />
          <Route exact path="/realisations" element={<Realisations />} />
          <Route exact path="/rendez-vous" element={<RendezVous />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default Navigation;
