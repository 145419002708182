import React from "react";
import "./styles.css";
import { Spin } from "antd";

function LoadingScreenTransparent() {
  return (
    <div className="loading_screen_transparent">
      <Spin spinning={true} style={{ fontSize: 50, marginBottom: 20 }} />
      <img
        src={require("../../../images/logos/logo-3.webp")}
        alt="Yeva Nails 6"
        className="header-logo"
      />
    </div>
  );
}

export default LoadingScreenTransparent;
