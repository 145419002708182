import React from "react";
import { Openning, RealisationsList } from "../../organims";
import { useSelector } from "react-redux";
import LoadingScreen from "../loading-screen/LoadingScreen";

function RealisationsTemplate() {
  const { dataLoading } = useSelector((state) => state.categoryReducer);
  return dataLoading ? (
    <LoadingScreen />
  ) : (
    <div>
      <div className="main_container">
        <div className="prestation_title_container">
          <div className="prestation_title">
            <h2>
              <span>Notre galerie</span>
            </h2>
          </div>
        </div>
      </div>
      <RealisationsList />
      <Openning />
    </div>
  );
}

export default RealisationsTemplate;
