import React from "react";
import "./styles.css";
import HeaderMenu from "../../mollecules/header-menu/HeaderMenu";

function HeaderRight() {
  return (
    <div className="header-right">
      <HeaderMenu />
    </div>
  );
}

export default HeaderRight;
