import { Col, Row, Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { filteredDate } from "../../../utils/dateList";
import { newReservation } from "../../../redux/actions/categoryActions";
import LoadingScreen from "../loading-screen/LoadingScreen";

function AppointmentTemplate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    activePrestatrice,
    prestatrices,
    activeDate,
    activeTime,
    activeCategory,
    activeService,
    selectedOptions,
    options,
    dataLoading,
    selectedCoiffureOptions,
  } = useSelector((state) => state.categoryReducer);

  const [prestatrice, setPrestatrice] = useState(null);
  const [price, setPrice] = useState(0);
  const [activeOptions, setActiveOptions] = useState([]);
  useEffect(() => {
    if (!activePrestatrice) {
      navigate("/reservation");
    } else {
      const index = prestatrices.findIndex(
        (item) => item._id === activePrestatrice
      );
      setPrestatrice(prestatrices[index]);
    }
    let totalPrice = 0;

    if (activeCategory.short === "Coiffure") {
      if (selectedCoiffureOptions) {
        let optionsList = [selectedCoiffureOptions];
        const filteredOptions = optionsList.map((id) => {
          const option = options[options.findIndex((item) => item._id === id)];
          totalPrice = totalPrice + option.price;
          return {
            _id: option._id,
            name: option.name,
            price: option.price,
            duration: option.duration,
          };
        });
        setActiveOptions(filteredOptions);
      }
    } else {
      totalPrice = activeService.price;
      const filteredOptions = selectedOptions.map((id) => {
        const option = options[options.findIndex((item) => item._id === id)];
        totalPrice = totalPrice + option.price;
        return { _id: option._id, name: option.name, price: option.price };
      });
      setActiveOptions(filteredOptions);
    }
    setPrice(totalPrice);
  }, [
    activeCategory.short,
    activePrestatrice,
    activeService.price,
    navigate,
    options,
    prestatrices,
    selectedCoiffureOptions,
    selectedOptions,
  ]);

  const onFinish = (values) => {
    const goToCalendar = () => navigate("/reservation");
    const body = {
      category: activeCategory,
      service: activeService,
      date: activeDate,
      time: activeTime,
      prestatrice: activePrestatrice,
      options: activeOptions,
      ...values,
    };
    newReservation(body, goToCalendar, dispatch);
  };
  return dataLoading ? (
    <LoadingScreen />
  ) : (
    <div className="main_container">
      {activeDate &&
        activeTime &&
        activeCategory &&
        activeService &&
        activeService &&
        prestatrice && (
          <Row gutter={30}>
            <Col span={24}>
              <div className="appointment-informations-container">
                <div className="appointment-informations">
                  <h2 className="appointment-informations-content">
                    Votre réservation
                  </h2>
                  <Row>
                    <Col span={18}>
                      <div className="appointment-informations-content appointment-informations-right-border">
                        {filteredDate(activeDate)}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="appointment-informations-content">
                        {activeTime}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      <div>
                        <div className="py5 px10 appointment-informations-right-border">
                          {activeService.name}
                        </div>
                        {activeOptions.map((option) => {
                          return (
                            <div
                              key={option._id}
                              className="py5 px10 appointment-informations-right-border"
                            >
                              - {option.name}
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="p10 appointment-informations-right-border">
                        {activeCategory.short !== "Coiffure" &&
                        selectedOptions.length > 0
                          ? `${selectedOptions[0].duration}min`
                          : `${activeService.duration}min`}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div>
                        <div className="py5 px10 textCenter textBold">
                          {activeCategory.short !== "Coiffure"
                            ? `${activeService.price}€`
                            : ""}
                        </div>
                        {activeOptions.map((option) => {
                          return (
                            <div
                              key={option._id}
                              className="py5 px10 textCenter textBold"
                            >
                              {option.price}€
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="p10 appointment-price-border">
                  Prix Total : {price}€
                </div>
                <div className="form-container">
                  <Form
                    layout="vertical"
                    name="basic"
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label={<label className="form-label">Nom</label>}
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez renseigner votre nom!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Votre nom..."
                        className="rendez_vous_form_input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<label className="form-label">Prénom</label>}
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez renseigner votre prénom!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Votre prénom..."
                        className="rendez_vous_form_input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<label className="form-label">E-mail</label>}
                      name="email"
                    >
                      <Input
                        placeholder="Votre E-mail..."
                        className="rendez_vous_form_input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <label className="form-label">
                          Numéro de téléphone (exemple : 0612345678)
                        </label>
                      }
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message:
                            "Veuillez renseigner votre numéro de téléphone!",
                        },
                        {
                          pattern: /^0\d{9}$/,
                          message:
                            "Entrez un numéro de téléphone au format 0612345678",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Votre numéro de téléphone..."
                        className="rendez_vous_form_input"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="form-button"
                        type="primary"
                        htmlType="submit"
                      >
                        Prendre rendez-vous
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        )}
    </div>
  );
}

export default AppointmentTemplate;
