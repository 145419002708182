import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

function RealisationsList() {
  const { realisations } = useSelector((state) => state.categoryReducer);
  return (
    <div className="main_container realisations_list">
      <Row gutter={[30, 30]}>
        {realisations.map((realisation) => (
          <Col lg={8} xs={24} key={realisation._id}>
            <img
              src={realisation.image}
              alt="realisation"
              className="realisation_image"
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default RealisationsList;
