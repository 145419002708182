import React from "react";
import "./styles.css";
import {
  HomeAdvantage,
  HomeServices,
  HomeTopPage,
  Openning,
  ServiceTitle,
} from "../../organims";
import { useSelector } from "react-redux";
import LoadingScreen from "../loading-screen/LoadingScreen";

function HomeTemplate() {
  const { dataLoading } = useSelector((state) => state.categoryReducer);
  return dataLoading ? (
    <LoadingScreen />
  ) : (
    <div>
      <HomeTopPage />
      <ServiceTitle />
      <HomeServices />
      <HomeAdvantage />
      <Openning />
    </div>
  );
}

export default HomeTemplate;
