const api = process.env.REACT_APP_API_URL;

export const GET_DATAS_URL = `${api}/api/datas`;
export const GET_CATEGORIES_URL = `${api}/api/categories`;
export const GET_PRESTATRICES_URL = `${api}/api/prestatrices`;
export const GET_CALENDAR_URL = `${api}/api/calendar`;
export const GET_REALISATIONS_URL = `${api}/api/realisations`;
export const GET_RESERVATIONS_URL = `${api}/api/reservations`;
export const NEW_RESERVATION_URL = `${api}/api/new-reservation`;
export const CANCEL_RESERVATION_URL = `${api}/api/cancel-reservation`;
