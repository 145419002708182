import { Col, Row } from "antd";
import React from "react";
import "./styles.css";

function ServiceTitle() {
  return (
    <div className="main_container">
      <div className="service-title-container">
        <Row>
          <Col span={8}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{ height: 1, backgroundColor: "white", width: "100%" }}
              ></div>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="service_title">
                <h2>
                  <span>Nos</span>Services
                </h2>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{ height: 1, backgroundColor: "white", width: "100%" }}
              ></div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ServiceTitle;
