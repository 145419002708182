import React, { useEffect } from "react";
import "./styles.css";
import { HeaderLogo } from "../../atoms";
import { HeaderRight } from "../../organims";
import { useLocation } from "react-router-dom";

function Header() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <header className="header">
      <div className="header-container">
        <HeaderLogo />
        <HeaderRight />
      </div>
    </header>
  );
}

export default Header;
