import { Col, Row } from "antd";
import React from "react";
import { PrestationsList, PrestationsMenu } from "../../mollecules";
import "./styles.css";

function PrestationsBlock({ activeCategory }) {
  return (
    <div className="main_container">
      <div className="prestations-block">
        <Row gutter={30}>
          <Col lg={7} xs={24}>
            <PrestationsMenu />
          </Col>
          <Col lg={17} xs={24}>
            <PrestationsList activeCategory={activeCategory} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PrestationsBlock;
