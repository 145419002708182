import React from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./styles.css";

function HomeServices() {
  const { categories } = useSelector((state) => state.categoryReducer);
  return (
    <div className="main_container home_service_container">
      <Row gutter={[50, 50]}>
        {categories.map(
          (category) =>
            category.services.length > 0 && (
              <Col lg={8} md={12} xs={24} key={category._id}>
                <Link to={category.url}>
                  <div
                    className="home-service-card-container "
                    style={{
                      backgroundImage: `url(${category.categoryImage})`,
                    }}
                  >
                    <div className="home-service-card-content">
                      <div className="home-service-card-title">
                        {category.name}
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            )
        )}
      </Row>
    </div>
  );
}

export default HomeServices;
