import { Button } from "antd";
import React from "react";
import "./styles.css";

function MyButton({ title, onClick }) {
  return (
    <Button className="my-button" onClick={onClick}>
      {title}
    </Button>
  );
}

export default MyButton;
