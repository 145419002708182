import React, { useEffect, useState } from "react";
import { HeaderLink, HeaderLinkMobile } from "../../atoms";
import "./styles.css";
import { navLinks } from "../../../utils/nav-links/navLinks";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { Drawer } from "antd";

function HeaderMenu() {
  const { categories } = useSelector((state) => state.categoryReducer);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const index = navLinks.findIndex((item) => item.title === "Prestations");
    if (categories.length > 0) {
      navLinks[index] = {
        ...navLinks[index],
        href: categories[0].url,
      };
    } else if (index >= 0) {
      navLinks.splice(index, 1);
    }
    
  }, [categories]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="header-menu-container">
      {open && (
        <Drawer
          placement={"left"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"left"}
        >
          <div style={{ backgroundColor: "gray", height: "100%", padding: 20 }}>
            {navLinks.map((link) => (
              <HeaderLinkMobile link={link} key={link.id} setOpen={setOpen} />
            ))}
          </div>
        </Drawer>
      )}
      <svg width="1em" height="1em">
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#B07E33" offset="0%" />
          <stop stopColor="#C7A570" offset="25%" />
          <stop stopColor="#D7B357" offset="50%" />
          <stop stopColor="#AA8854" offset="75%" />
          <stop stopColor="#6F5124" offset="100%" />
        </linearGradient>
      </svg>
      <div className="header-menu">
        {navLinks.map((link) => (
          <HeaderLink link={link} key={link.id} />
        ))}
      </div>
      <div className="header-menu-mobile" onClick={showDrawer}>
        <GiHamburgerMenu
          className="menu-mobile"
          style={{ fill: "url(#blue-gradient)" }}
        />
      </div>
    </div>
  );
}

export default HeaderMenu;
