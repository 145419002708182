import React from "react";
import {
  Openning,
  ReservationCalendar,
  PrestatricesCalendar,
  PrestationsCalendar,
} from "../../organims";
import { useSelector } from "react-redux";
import "./styles.css";
import LoadingScreen from "../loading-screen/LoadingScreen";

function ReservationTemplate() {
  const { selectedService, dataLoading } = useSelector(
    (state) => state.categoryReducer
  );

  return dataLoading ? (
    <LoadingScreen />
  ) : (
    <div>
      <div className="main_container">
        <div className="prestation_title_container">
          <div className="prestation_title">
            <h2>
              <span>Calendrier & Reservations</span>
            </h2>
          </div>
        </div>
      </div>
      <PrestationsCalendar />
      {selectedService && <ReservationCalendar />}
      {selectedService && <PrestatricesCalendar />}
      <Openning />
    </div>
  );
}

export default ReservationTemplate;
