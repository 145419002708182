import React, { useEffect, useState } from "react";
import "./styles.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { daysList } from "../../../utils/dateList";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveDate,
  changeCalendarIndex,
  setActiveCalendarArray,
} from "../../../redux/actions/categoryActions";
import { LoadingScreenTransparent } from "../../templates";

function ReservationCalendar() {
  const {
    calendarLoading,
    appointmentsIndex,
    activeDate,
    activeCalendarArray,
    selectedService,
    services,
  } = useSelector((state) => state.categoryReducer);

  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    let activeCalendarTemp = [...activeCalendarArray];
    let activeServiceIndex = services.findIndex(
      (item) => item._id === selectedService
    );
    let activeService = services[activeServiceIndex];

    for (let i = 0; i < activeCalendarTemp.length; i++) {
      for (let j = 0; j < activeCalendarTemp[i].calendar.length; j++) {
        if (activeCalendarTemp[i].calendar[j].active) {
          for (
            let k = 0;
            k < activeCalendarTemp[i].calendar[j].prestatrices.length;
            k++
          ) {
            const prestatrice =
              activeCalendarTemp[i].calendar[j].prestatrices[k];
            if (
              prestatrice.appointmentSchedule[0][
                `appointments_${activeService.duration}`
              ].length > 0 &&
              prestatrice.services.includes(selectedService)
            ) {
              activeCalendarTemp[i].calendar[j] = {
                ...activeCalendarTemp[i].calendar[j],
                visible: true,
              };
              break;
            } else {
              activeCalendarTemp[i].calendar[j] = {
                ...activeCalendarTemp[i].calendar[j],
                visible: false,
              };
            }
          }
        }
      }
    }
    if (activeCalendarArray.length > 0) {
      const allCalendar = [
        ...activeCalendarTemp[0].calendar,
        ...activeCalendarTemp[1].calendar,
        ...activeCalendarTemp[2].calendar,
      ];
      const currentDateIndex = allCalendar.findIndex(
        (item) => item.date === activeDate
      );
      const currentDate = allCalendar[currentDateIndex];
      if (!currentDate.visible) {
        changeActiveDate(allCalendar[currentDateIndex + 1].date, dispatch);
      }
      setActiveCalendarArray(activeCalendarTemp, dispatch);
    } else {
      setRefresh(!refresh);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate, selectedService, refresh]);

  const onChangeCalendarIndex = (value) => {
    const calendar = activeCalendarArray[value].calendar;
    for (let i = 0; i < calendar.length; i++) {
      if (calendar[i].date && calendar[i].visible && calendar[i].active) {
        changeCalendarIndex(value, dispatch);
        return changeActiveDate(calendar[i].date, dispatch);
      }
    }
  };

  const onchangeActiveDate = (value) => {
    changeActiveDate(value, dispatch);
  };
  return (
    <div className="main_container">
      {calendarLoading && (
        <div style={{ position: "absolute", height: "100%", width: "100%" }}>
          <LoadingScreenTransparent />
        </div>
      )}
      <div className="reservation-calendar-container">
        {!calendarLoading && activeCalendarArray.length > 0 && (
          <div className="reservation-canlendar">
            <div className="reservation-calendar-month">
              {appointmentsIndex > 0 ? (
                <FaChevronLeft
                  onClick={() => onChangeCalendarIndex(appointmentsIndex - 1)}
                />
              ) : (
                <FaChevronLeft color="lightgray" />
              )}
              {activeCalendarArray[appointmentsIndex]?.name}
              {appointmentsIndex < activeCalendarArray.length - 1 ? (
                <FaChevronRight
                  onClick={() => onChangeCalendarIndex(appointmentsIndex + 1)}
                />
              ) : (
                <FaChevronRight color="lightgray" />
              )}
            </div>
            <div className="reservation-calendar-item-container">
              {daysList.map((d) => (
                <div key={d.id} className="calendar-date-container">
                  {d.shortName}
                </div>
              ))}
            </div>
            <div className="reservation-calendar-item-container">
              {activeCalendarArray[appointmentsIndex].calendar.map((d, i) =>
                d.active ? (
                  !d.visible ? (
                    <div key={i} className="calendar-date-container">
                      <div className="calendar-date-red">{d.day}</div>
                    </div>
                  ) : (
                    <div
                      key={i}
                      className="calendar-date-container"
                      onClick={() => onchangeActiveDate(d.date)}
                    >
                      <div
                        className={
                          activeDate === d.date
                            ? "calendar-date active-calendar-date"
                            : "calendar-date"
                        }
                      >
                        {d.day}
                      </div>
                    </div>
                  )
                ) : (
                  <div key={i} className="calendar-date-container"></div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReservationCalendar;
