import { Col, Row } from "antd";
import React from "react";
import "./styles.css";
import { useSelector } from "react-redux";

function HomeTopPage() {
  const { informations } = useSelector((state) => state.categoryReducer);
  return (
    <div className="main_container">
      <Row gutter={30}>
        <Col lg={10} xs={24}>
          <div className="header_text_container">
            <h3>Bienvenue chez</h3>
            <div className="title_container">
              <h1>Yeva Nails</h1>
            </div>
          </div>
        </Col>
        <Col lg={14} xs={24}>
          <div className="home_top_page_image_container">
            <Row>
              <Col span={12}>
                <img
                  loading="lazy"
                  className="home_top_page_image_1"
                  src={informations?.homeImage1}
                  alt="Yeva Nails 3"
                  width="720"
                  height="470"
                />
              </Col>
              <Col span={12}>
                <img
                  className="home_top_page_image_2"
                  src={informations?.homeImage2}
                  alt="Yeva Nails 4"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default HomeTopPage;
