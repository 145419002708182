import React from "react";
import "./styles.css";
import { Col, Row } from "antd";
import { MyButton } from "../../atoms";
import { useNavigate } from "react-router-dom";
import {
  setSelectedCategory,
  setSelectedService,
} from "../../../redux/actions/categoryActions";
import { useDispatch } from "react-redux";

function PrestationsList({ activeCategory }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { services, options } = activeCategory;
  const onBook = (serviceId) => {
    setSelectedCategory(activeCategory._id, dispatch);
    setSelectedService(serviceId, dispatch);
    navigate("/reservation");
  };
  const renderService = () => {
    let index = 0;
    return (
      <>
        {services &&
          services
            .sort((a, b) => a.order - b.order)
            .map((service) => {
              index++;
              if (activeCategory.short === "Coiffure") {
                return (
                  <Col
                    span={24}
                    key={service._id}
                    className={index % 2 ? "prestations-list-item-bold" : ""}
                    style={{
                      paddingTop: index === 0 ? 10 : 0,
                      paddingBottom: index === services.length - 1 ? 10 : 0,
                    }}
                  >
                    <div>
                      <div className="prestations-list-item">
                        <div className="service-name">
                          {service.name}
                          <span>({service.duration}min)</span>
                        </div>
                        <div className="separator" />
                        <div className="reservation-button">
                          <MyButton
                            title="Réserver"
                            onClick={() => onBook(service._id)}
                          />
                        </div>
                      </div>
                      {options
                        .sort((a, b) => a.order - b.order)
                        .filter((option) => option.subCategory === service._id)
                        .map((option) => (
                          <div className="prestations-list-item">
                            <div className="service-name">
                              {option.name}
                              <span>({option.duration}min)</span>
                            </div>
                            <div className="separator" />
                            <div className="price">{option.price}€</div>
                          </div>
                        ))}
                    </div>
                  </Col>
                );
              } else {
                return (
                  <Col
                    span={24}
                    key={service._id}
                    className={index % 2 ? "prestations-list-item-bold" : ""}
                    style={{
                      paddingTop: index === 0 ? 10 : 0,
                      paddingBottom: index === services.length - 1 ? 10 : 0,
                    }}
                  >
                    <div className="prestations-list-item">
                      <div className="service-name">
                        {service.name}
                        <span>({service.duration}min)</span>
                      </div>
                      <div className="separator" />
                      <div className="price">{service.price}€</div>
                      <div className="reservation-button">
                        <MyButton
                          title="Réserver"
                          onClick={() => onBook(service._id)}
                        />
                      </div>
                    </div>
                  </Col>
                );
              }
            })}
        {activeCategory.short !== "Coiffure" &&
          options &&
          options
            .sort((a, b) => a.order - b.order)
            .map((service) => {
              index++;
              return (
                <Col
                  span={24}
                  key={service._id}
                  className={index % 2 ? "prestations-list-item-bold" : ""}
                  style={{
                    paddingTop: index === 0 ? 10 : 0,
                    paddingBottom: index === options.length - 1 ? 10 : 0,
                  }}
                >
                  <div className="prestations-list-item">
                    <div className="service-name">{service.name}</div>
                    <div className="separator" />
                    <div className="price">{service.price}€</div>
                    <div className="reservation-button">
                      <div className="supplement-text">Supplément</div>
                    </div>
                  </div>
                </Col>
              );
            })}
      </>
    );
  };
  return (
    <div className="prestations-list">
      <Row gutter={20} style={{ height: "100%" }}>
        {renderService()}
      </Row>
    </div>
  );
}

export default PrestationsList;
