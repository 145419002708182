import { Col, Row } from "antd";
import React from "react";
import "./styles.css";
import { useSelector } from "react-redux";

function HomeAdvantage() {
  const { informations } = useSelector((state) => state.categoryReducer);
  const advantagesList = [
    { id: 1, title: "Une proximité avec vous" },
    { id: 2, title: "Des années d'expériences" },
    { id: 3, title: "Une relation clientèle d'exception" },
    { id: 4, title: "Des expertes dans chacun de nos domaines" },
  ];
  return (
    <div className="main_container">
      <div className="home-advantage">
        <Row>
          <Col lg={12} xs={24}>
            <div>
              <div className="home-advantage-title-container">
                <div className="home-advantage-title-content">
                  <h2>Nos avantages</h2>
                  <img
                    className="ellipse-image"
                    src={require("../../../images/backgrounds/Ellipse-1.webp")}
                    alt="ellipse"
                  />
                  <img
                    className="nails-image"
                    src={require("../../../images/photos/nail.webp")}
                    alt="nails"
                  />
                </div>
              </div>
              <div className="advantage-list">
                {advantagesList.map((advantage) => (
                  <div key={advantage.id} className="advantage-list-item">
                    <img
                      src={require("../../../images/backgrounds/Frame-7.webp")}
                      alt="check"
                    />
                    <div>{advantage.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={12} xs={24}>
            <div className="advantage-image-container">
              <img
                src={informations?.advantageImage}
                className="advantage-image"
                alt="Yeva Nails 2"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default HomeAdvantage;
